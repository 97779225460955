import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import CytoscapeComponent from "react-cytoscapejs";
import { ElementDefinition, Stylesheet } from "cytoscape";
import { IOwnershipTreeResponse } from "pages/Company/Tabs/Ubo/types";
import PersonIcon from "assets/person_thin.svg";
import CompanyIcon from "assets/office.svg";
import CompanyIconRFNC from "assets/office_rfnc.svg";
import RepresentativesIcon from "assets/briefcase.svg";
import IconBackground from "assets/circle.svg";
import JointShareholder from "assets/joint.svg";
import dagre from "cytoscape-dagre";
import Cytoscape from "cytoscape";
import mergeImages, { ImageSource } from "merge-images";
import { GraphOrientation } from "pages/Company/Tabs/Ubo/types";
import { Grid } from "pages/Company/types";
import {
  azul,
  black,
  cherry,
  middark,
  primary_mid,
  scarlet,
  secondary,
  primary_dark,
} from "pages/variables";

import { LayoutOptions } from "../../pages/Company/Tabs/Ubo/types";
import popper from "cytoscape-popper";
import { createRoot } from "react-dom/client";
import NodePopup from "./NodePopup";
import { Popper } from "contexts/NodePopupContext";
import { JointHoldingGroup, NodeShareholdings, Shareholding } from "./types";
import { Userpilot } from "userpilot";

Cytoscape.use(dagre);
Cytoscape.use(popper);

type Props = {
  setShowGraph: (value: boolean) => void;
  movable?: boolean;
  style?: React.CSSProperties;
  grid?: Grid;
  cyRef: Cytoscape.Core | null;
  setCyRef: React.Dispatch<React.SetStateAction<Cytoscape.Core | null>>;
  isGraphReady: boolean;
  setIsGraphReady: (value: boolean) => void;
  ownershipTree: IOwnershipTreeResponse | undefined;
  orientation: GraphOrientation;
  styleLoaded: boolean;
  setStyleLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  layout: LayoutOptions;
  setLayout: React.Dispatch<LayoutOptions>;
  showRepresentatives: boolean;
  isActive: boolean;
  popperCollection: Record<string, Popper | null>;
  clearNodePopups: () => void;
  isInitialUnwrap: boolean;
};
const createContentFromComponent = (component: JSX.Element) => {
  const dummyDomEle = document.createElement("div");
  const root = createRoot(dummyDomEle);
  root.render(component);
  document.body.appendChild(dummyDomEle);
  return dummyDomEle;
};

const UboVisualization: React.FC<Props> = ({
  setShowGraph,
  movable,
  style,
  grid,
  cyRef,
  setCyRef,
  isGraphReady,
  setIsGraphReady,
  ownershipTree,
  orientation,
  styleLoaded,
  setStyleLoaded,
  layout,
  setLayout,
  showRepresentatives,
  isActive,
  popperCollection,
  clearNodePopups,
  isInitialUnwrap,
}): JSX.Element => {
  const defaultStylesheet: Stylesheet[] = [
    {
      selector: "node",
      style: {
        width: 150,
        height: 150,
        "text-wrap": "wrap",
        "text-max-width": "400px",
        "text-valign": "bottom",
        label: "data(label)",
        "background-width": "100x",
        "background-height": "100px",
        "overlay-padding": "6px",
        "z-index": 10,
        color: black,
        "font-size": "30",
        "text-margin-y": 10,
        "line-height": 1.25,
        "text-background-color": "#FAFAFA",
        "font-weight": 700,
        "text-background-padding": "1px",
        "text-background-opacity": 1,
        "font-family": "Anthro",
      },
    },
    {
      selector: "node:selected",
      style: {
        "border-width": "6px",
        "border-color": "#AAD8FF",
        "border-opacity": 0.5,
        "background-color": "#77828C",
        width: 150,
        height: 150,
        "text-outline-color": "#77828C",
        "text-outline-width": 8,
        color: "white",
      },
    },
    {
      selector: "node[type='OTHER']",
      style: {
        "background-image": CompanyIcon,
      },
    },
    {
      selector: "node[type='RFNC']",
      style: {
        "background-image": CompanyIconRFNC,
      },
    },
    {
      selector: "node[type='REPRESENTATIVE']",
      style: {
        "background-image": RepresentativesIcon,
        backgroundColor: primary_mid,
      },
    },
    {
      selector: "edge",
      style: {
        width: 2,
        "line-color": secondary,
        "target-arrow-color": secondary,
        "target-arrow-shape": "triangle",
        "curve-style": "bezier",
      },
    },
    {
      selector: "edge.circular",
      style: {
        width: 2,
        "line-color": scarlet,
        "target-arrow-color": scarlet,
      },
    },
    {
      selector: "edge:selected",
      style: {
        width: 2,
        "line-color": primary_mid,
        "target-arrow-color": primary_mid,
        "target-arrow-shape": "triangle",
        "curve-style": "bezier",
      },
    },
    {
      selector: "edge[label]",
      style: {
        label: "data(label)",
        "font-family": "Anthro",
        "font-size": "30",
        "font-weight": 700,
        "text-background-color": "#FAFAFA",
        "text-background-opacity": 1,
        "text-background-padding": "2px",
        "text-margin-y": -4,
        "text-events": "yes",
        color: middark,
      },
    },
  ];

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [styleSheet, setStylesheet] = useState<Stylesheet[]>(defaultStylesheet);
  const [nodeGrabEnabled] = useState<boolean>(movable ?? false);
  const [graphElements, setGraphElements] = useState<ElementDefinition[]>(
    [] as ElementDefinition[],
  );
  const [jointHoldingGroups, setJointHoldingGroups] = useState<
    JointHoldingGroup[]
  >([]);
  const [cachedIcons] = useState<Record<string, string>>({});
  const [representativeGraphElements, setRepresentativeGraphElements] =
    useState<ElementDefinition[]>([] as ElementDefinition[]);

  const formatOwnership = (ownership: number): number => {
    if (isNaN(ownership)) {
      return ownership;
    }

    return Math.round((ownership + Number.EPSILON) * 100) / 100;
  };

  const isUbo = useCallback(
    (id: string) => {
      if (ownershipTree?.ownershipTree.ultimateBeneficialOwners) {
        return ownershipTree?.ownershipTree.ultimateBeneficialOwners.find(
          (x) => x.id == id,
        )
          ? true
          : false;
      }
      return false;
    },
    [ownershipTree?.ownershipTree.ultimateBeneficialOwners],
  );

  useEffect(() => {
    setLayout({ ...layout, rankDir: orientation });
    // TODO:: Bad use of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation]);

  useEffect(() => {
    const restruct = (
      ownershipTree: IOwnershipTreeResponse,
    ): {
      nodes: ElementDefinition[];
      edges: ElementDefinition[];
      representativeNodes: ElementDefinition[];
      representativeEdges: ElementDefinition[];
      jointHoldingGroups: JointHoldingGroup[];
    } => {
      const nodes: ElementDefinition[] = [];
      const edges: ElementDefinition[] = [];
      const representativeNodes: ElementDefinition[] = [];
      const representativeEdges: ElementDefinition[] = [];

      const getShareholdings = (nodeId: string): NodeShareholdings => {
        const result: NodeShareholdings = { shareholdings: [] };
        for (const node of ownershipTree.ownershipTree.nodes) {
          if (node.edges) {
            for (const edge of node.edges) {
              if (edge.nodeId == nodeId) {
                result.shareholdings.push({
                  parentNode: node.entity.nameInEnglish,
                  shareholding: edge.shareholdings,
                  percentage: node.level == "1" ? 100 : node.rollupPercentage,
                } as Shareholding);
              }
            }
          }
        }

        return result;
      };
      const jointHoldingGroups: JointHoldingGroup[] = [];
      for (const n of ownershipTree.ownershipTree.nodes) {
        const isRepresentative =
          n.rollupPercentage == null && n.entity.type == "PERSON";

        let type =
          n.entity.reasonForNonContinuation != null ? "RFNC" : n.entity.type;

        if (type == "PERSON" && n.rollupPercentage == null) {
          type = "REPRESENTATIVE";
        }

        const node: ElementDefinition = {
          data: {
            id: "node" + n.entity.id,
            name: n.entity.name,
            type: type,
            entityType: n.entity.type,
            label: `${
              n.entity.nameInEnglish ? n.entity.nameInEnglish.toUpperCase() : ""
            }\n${
              n.rollupPercentage
                ? formatOwnership(n.rollupPercentage).toString() + "%"
                : ""
            }`,
            isUbo: isUbo(n.entity.id),
            rollupPercentage:
              n.rollupPercentage != null
                ? formatOwnership(n.rollupPercentage).toString() + "%"
                : "",
            countryIso: n.entity.countryISO?.substring(0, 2),
            companyCode: n.entity.companyCode,
            address:
              n.level == "1" ? ownershipTree.ownershipTree.address : null,
            profileLink: n.entity.links?.enhancedProfile,
            rfnc: n.entity.reasonForNonContinuation,
            registrationAuthority: n.entity.registrationAuthority,
            shareholdings:
              n.rollupPercentage != null ? getShareholdings(n.entity.id) : null,
          },
        };

        if (isRepresentative) {
          representativeNodes.push(node);
        } else {
          nodes.push(node);
        }

        if (Array.isArray(n.edges)) {
          for (const e of n.edges) {
            const edge: ElementDefinition = {
              data: {
                id: `${"node" + n.entity.id}_${"node" + e.nodeId}`,
                source: "node" + n.entity.id,
                target: "node" + e.nodeId,
                label:
                  e.percentage != null
                    ? Number(Number(e.percentage).toFixed(2)) + "%"
                    : "",
                role: e.role,
              },
            };
            edge.classes = e.isCircular ? "circular" : undefined;
            if (e.type == "REPRESENTATIVE") {
              representativeEdges.push(edge);
            } else {
              edges.push(edge);

              if (e.shareholdings) {
                for (const s of e.shareholdings) {
                  if (s.isJointlyHeld) {
                    let jointHoldingGroup = jointHoldingGroups.find(
                      (j) => j.jointHoldingGroupId == s.jointHoldingGroupId,
                    );
                    if (!jointHoldingGroup) {
                      jointHoldingGroups.push({
                        jointHoldingGroupId: s.jointHoldingGroupId,
                        shareholders: [],
                      } as JointHoldingGroup);
                    }

                    jointHoldingGroup = jointHoldingGroups.find(
                      (j) => j.jointHoldingGroupId == s.jointHoldingGroupId,
                    );

                    const name = ownershipTree.ownershipTree.nodes.find(
                      (n) => n.entity.id == e.nodeId,
                    )?.entity.name;
                    if (name) {
                      jointHoldingGroup?.shareholders.push(name);
                    }
                  }
                }
              }
            }
          }
        }
      }

      // get the role of representatives from its parent's edge and add it to the node
      for (const repNode of representativeNodes) {
        repNode.data.role = representativeEdges.find(
          (x) => x.data.target == repNode.data.id,
        )?.data.role;
      }

      return {
        nodes,
        edges,
        representativeNodes,
        representativeEdges,
        jointHoldingGroups,
      };
    };

    if (ownershipTree) {
      const restructuredData = restruct(ownershipTree);
      setJointHoldingGroups(restructuredData.jointHoldingGroups);
      setGraphElements(CytoscapeComponent.normalizeElements(restructuredData));
      setRepresentativeGraphElements(
        CytoscapeComponent.normalizeElements({
          nodes: restructuredData.representativeNodes,
          edges: restructuredData.representativeEdges,
        }),
      );

      if (!styleLoaded) {
        updateStyle();
        setStyleLoaded(true);
      }
    }
    // TODO:: All of this doesn't need a useEffect and there's so many side effects to capture
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownershipTree, isUbo]);

  useEffect(() => {
    if (graphElements && graphElements.length) {
      setIsGraphReady(true);
    }
    // TODO:: Move out of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphElements]);

  const flagSvgExists = (countryIso: string): boolean => {
    try {
      require(`assets/circle-flags/${countryIso.toLowerCase()}.svg`);
      return true;
    } catch (err) {
      return false;
    }
  };

  // delete all poppers when not in UBO tab
  useEffect(() => {
    if (!isActive) {
      clearNodePopups();
    }
    // TODO:: bad use of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (cyRef) {
      cyRef.nodes().on("click", (event) => {
        Userpilot.track("UBO Visual Node Clicked");
        const nodeData = event.target.data();
        nodeData.jointHoldingGroups = jointHoldingGroups;
        const popper = popperCollection[event.target.id()];
        if (!popper) {
          const newPopper = event.target.popper({
            content: createContentFromComponent(
              <NodePopup
                nodeId={event.target.id() as string}
                popperCollection={popperCollection}
                nodeData={nodeData}
              />,
            ),
            popper: {
              placement: "bottom",
              removeOnDestroy: true,
              preventOverflow: { enabled: true },
            },
          });
          popperCollection[event.target.id() as keyof typeof popperCollection] =
            newPopper;
        }
      });

      // Update node pop up position when a node is moved
      cyRef.nodes().on("position", (event) => {
        const popper = popperCollection[event.target.id()];
        if (popper) {
          popper.update();
        }
      });

      // Update all popups position when graph is panned
      cyRef.on("pan", () => {
        if (popperCollection) {
          for (const key in popperCollection) {
            const value = popperCollection[key] as Popper;
            if (value) {
              value.update();
            }
          }
        }
      });
    }
    // TODO:: Does this need a useEffect?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyRef, showRepresentatives]);

  const updateStyle = useCallback(async () => {
    const getFlag = (countryIso: string): ImageSource => {
      return {
        src: require(`assets/circle-flags/${countryIso.toLowerCase()}.svg`),
        x: 335,
        y: 300,
      };
    };

    const IsJointShareholder = (nodeId: string): boolean => {
      if (ownershipTree?.ownershipTree?.nodes) {
        for (const node of ownershipTree?.ownershipTree?.nodes) {
          if (node.edges) {
            for (const edge of node.edges) {
              if (
                edge.nodeId === nodeId &&
                edge.shareholdings &&
                edge.shareholdings.find((s) => s.isJointlyHeld)
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    };

    setStylesheet(defaultStylesheet);

    if (!ownershipTree?.ownershipTree?.nodes) {
      return;
    }

    for (const node of ownershipTree?.ownershipTree?.nodes) {
      const isRepresentative =
        node.entity.type == "PERSON" && node.rollupPercentage == null;
      const isRfnc = node.entity.reasonForNonContinuation != null;
      const hasFlag = node.entity.countryISO != null;
      const hasRollupPercentage = node.rollupPercentage != null;

      const isJointShareholder = IsJointShareholder(node.entity.id);
      if (isRepresentative || (isRfnc && !hasFlag && !hasRollupPercentage)) {
        continue;
      }
      let Icon: ImageSource;
      const withFlag =
        node.entity.countryISO != null && flagSvgExists(node.entity.countryISO);
      let flag: ImageSource | null;

      let baseKey;

      if (node.entity.type == "PERSON") {
        baseKey = isJointShareholder ? "JOINT" : "PERSON";
      } else {
        baseKey = isRfnc ? "rfnc" : "company";
      }
      const key = baseKey + (withFlag ? node.entity.countryISO : "");
      let finalIcon = null;
      if (cachedIcons[key] != null) {
        finalIcon = cachedIcons[key];
      } else {
        if (node.entity.type == "PERSON") {
          Icon = {
            src: isJointShareholder ? JointShareholder : PersonIcon,
            x: 415,
            y: 360,
          };
        } else {
          Icon = {
            src: isRfnc ? CompanyIconRFNC : CompanyIcon,
            x: 430,
            y: 365,
          };
        }

        const imagesToCombine: ImageSource[] = [IconBackground, Icon];

        if (withFlag) {
          flag = getFlag(node.entity.countryISO);
          imagesToCombine.push(flag);
        }
        finalIcon = await mergeImages(imagesToCombine, { quality: 1 });
        cachedIcons[key] = finalIcon;
      }

      let bgColor = azul;
      if (node.entity.type === "PERSON") {
        bgColor = isJointShareholder ? primary_dark : middark;
      }
      if (isUbo(node.entity.id)) {
        bgColor = secondary;
      } else if (isRfnc) {
        bgColor = cherry;
      }

      const nodeStyle: Stylesheet = {
        selector: `node[id='node${node.entity.id}']`,
        style: {
          "background-image": finalIcon,
          backgroundColor: bgColor,
          "background-opacity": 1,
          width: 150,
          height: 150,
          "background-clip": "none",
          "background-width": 400,
          "background-height": 400,
          "background-offset-x": -6,
          "background-offset-y": 10,
          "bounds-expansion": 120,
        },
      };

      setStylesheet((current) => [...current, nodeStyle]);

      if (node.edges) {
        for (const edge of node.edges) {
          if (
            edge.shareholdings &&
            edge.shareholdings.some((s) => s.isJointlyHeld)
          ) {
            const edgeStyle: Stylesheet = {
              selector: `edge[id='${node.entity.id}_${edge.nodeId}']`,
              style: {
                "line-style": "dashed",
                "line-dash-pattern": [10, 10],
              },
            };
            setStylesheet((current) => [...current, edgeStyle]);
          }
        }
      }
    }
    // TODO:: Split this out of useEffect, should be much smaller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownershipTree]);

  useEffect(() => {
    if (isGraphReady) {
      setShowGraph(true);
    }
  }, [isGraphReady, setShowGraph]);

  useEffect(() => {
    if (cyRef && isGraphReady) {
      cyRef.layout(layout).run();
      cyRef.fit();

      if (!showRepresentatives) {
        // destroy all representative node poppers when representative nodes are hidden
        if (popperCollection) {
          for (const repElem of representativeGraphElements.filter(
            (x) => x.data.type == "REPRESENTATIVE",
          )) {
            const popperId = repElem.data.id;
            if (popperId) {
              const popper = popperCollection[popperId];
              if (popper) {
                popper.destroy();
                popperCollection[popperId] = null;
              }
            }
          }
        }
      }
    }
    // TODO:: does this need to be in an effect or does cy support hooks?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRepresentatives]);

  return (
    <Box>
      {isGraphReady &&
        isActive &&
        grid &&
        (grid.isSingleLayout || isInitialUnwrap) && (
          <CytoscapeComponent
            elements={
              showRepresentatives
                ? [...graphElements, ...representativeGraphElements]
                : [...graphElements]
            }
            style={{
              width: "100%",
              height: "80vh",
              backgroundColor: "#FAFAFA",
              position: "relative",
              top: -155,
              ...style,
            }}
            zoomingEnabled={true}
            maxZoom={0.6}
            minZoom={0.1}
            autounselectify={false}
            boxSelectionEnabled={true}
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            layout={layout as any}
            stylesheet={styleSheet}
            autoungrabify={!nodeGrabEnabled}
            wheelSensitivity={0.5}
            // code for saving image
            // cy={(cy) => {
            //   setTimeout(function () {
            //     const image = cy.jpg({ full: true });
            //     // console.log(image)
            //     const img = document.createElement("img");
            //     img.src = image;
            //     const a = document.createElement("a"); //Create <a>
            //     a.href = image; //Image Base64 Goes here
            //     a.download = "Image.jpg"; //File name Her
            //     a.click(); //Downloaded file
            //   }, 1000);
            // }}
            cy={(cy) => {
              setCyRef(cy);
            }}
          />
        )}
    </Box>
  );
};

export default UboVisualization;
